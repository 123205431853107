(() => {
    const f = () => {
        if (
            window.TeneoWebChat &&
            typeof window.TeneoWebChat.initialize === 'function'
        ) {
            var element = document.getElementById('teneo-web-chat');
            // only setting prod url at the moment - requested by Raquell
            const teneoEngineUrl = process.env.CONFIG_CONTEXT.teneoEngineUrl + '/icapets/'

            sessionStorage.setItem('original_engine_url', teneoEngineUrl)

            let teneoEngineParams;
            
            const teneoProps = {
                'teneoEngineUrl': teneoEngineUrl,
                'teneoEngineParams': teneoEngineParams,
                'title': 'Hjälprobot',
                'sendIconUrl': 'https://assets.icanet.se/image/upload/digitalassistent/icons/send.svg',
                'launchIconUrl': 'https://assets.icanet.se/image/upload/v1623865838/digitalassistent/icons/chatbot--white.svg',
                'botAvatarUrl': 'https://assets.icanet.se/image/upload/v1623865838/digitalassistent/icons/chatbot--red.svg',
                'agentAvatarUrl': 'https://assets.icanet.se/image/upload/v1605706331/digitalassistent/icons/twc-agent.svg',
                'customLocalizations': {
                    'sv': {
                        message: {
                            header_minimize_button_title: 'Minimera chattfönstret',
                            header_minimize_button_aria_label: 'Minimera chattfönstret',
                            input_area_userinput_field_placeholder: 'Skriv här...',
                            input_area_send_button_title: 'Skicka',
                            header_close_button_aria_label: 'Stäng och avsluta konversation',
                            header_close_button_title: 'Stäng och avsluta konversation'
                        }
                    }
                },
                'locale': 'sv',
                'showCloseButton': true
            }
            window.TeneoWebChat.initialize(element, teneoProps);
        }
    };
    setTimeout(f, 0);
})();