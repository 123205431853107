/**
 * Class representing a Mitel live chat handler.
 */
export class MitelChatHandler {

    /**
     * Creates an instance of the chat Mitel live chat handler.
     *
     * @param {object} config the config object.
     *
     * @param {boolean} [config.bDebug=false] indicates in debug info should be written to the console (true) or if it should be supressed.
     *
     * @param {string} config.sChatBaseUrl the base URL of the Mitel chat backend.
     *
     * @param {number} [config.nRequestTimeoutMillis=25000] the timeout in milliseconds of the requests sent to the Mitel chat backend.
     *
     * @param {number} [config.nTenantID] the TenantID parameter as per the Mitel API documentation.
     * @param {number} [config.TenantID] an alternative to config.TenantID, added for compatibility reasons.
     *
     * @param {string} [config.sServiceGroupID] the ServiceGroupID parameter as per the Mitel API documentation.
     * @param {string} [config.ServiceGroupID] an alternative to config.sServiceGroupID, added for compatibility reasons.
     *
     * @param {string} [config.sServiceGroupName] the ServiceGroupName parameter as per the Mitel API documentation.
     * @param {string} [config.ServiceGroupName] an alternative to config.sServiceGroupName, added for compatibility reasons.
     *
     * @param {string} [config.sSessionID] the SessionID parameter as per the Mitel API documentation.
     * @param {string} [config.SessionID] an alternative to config.sSessionID, added for compatibility reasons.
     *
     * @param {string} [config.sCustomerID] the CustomerID parameter as per the Mitel API documentation.
     * @param {string} [config.CustomerID] an alternative to config.sCustomerID, added for compatibility reasons.
     *
     * @param {string} [config.sCustomerName] the CustomerName parameter as per the Mitel API documentation.
     * @param {string} [config.CustomerName] an alternative to config.sCustomerName, added for compatibility reasons.
     *
     * @param {string} [config.sEmailAddress] the EmailAddress parameter as per the Mitel API documentation.
     * @param {string} [config.EmailAddress] an alternative to config.sEmailAddress, added for compatibility reasons.
     *
     * @param {string} [config.sPrivateConnectionData] the private data submitted to the chap operator when the chat is connected.
     * @param {string} [config.PrivateConnectionData] an alternative to config.sPrivateConnectionData, added for compatibility reasons.
     *
     * @param {string} [config.sChatID] the ChatID parameter as per the Mitel API documentation.
     * @param {string} [config.ChatID] an alternative to config.sChatID, added for compatibility reasons.
     *
     * @throws {Error} argument config must be non-null.
     */
    constructor(config) {

        if (config == null) throw new Error('MitelChatHandler, config==null');
        if (config.bDebug) {
            this.bDebug = true;
            console.trace('MitelChatHandler, creating an instance with config', config);
        } else {
            this.bDebug = false;
        }
        this.callbackMap = {};

        var x = config.sChatBaseUrl;
        if (!x) throw new Error('MitelChatHandler, missing config.sChatBaseUrl value');
        if (!x.endsWith('/')) x += '/';

        this.sRequestChatUrl = x + 'RequestChat';
        this.sSendMessageUrl = x + 'SendMessage';
        this.sLeaveChatUrl = x + 'LeaveChat';
        this.sSendTypingUrl = x + 'SendTyping';
        this.sGetQueueInfoUrl = x + 'GetQueueInfo';
        this.sGetChatInfoUrl = x + 'GetChatInfo';
        this.sGetEventsUrl = x + 'GetEvents';
        this.sGetChatConfigurationUrl = x + 'GetChatConfiguration';

        this.nTenantID = config.nTenantID != null ? config.nTenantID : config.TenantID;
        this.sServiceGroupID = config.sServiceGroupID || config.ServiceGroupID;
        this.sServiceGroupName = config.sServiceGroupName || config.ServiceGroupName;
        this.sSessionID = config.sSessionID || config.SessionID;
        this.sCustomerID = config.sCustomerID || config.CustomerID;
        this.sCustomerName = config.sCustomerName || config.CustomerName;
        this.sEmailAddress = config.sEmailAddress || config.EmailAddress;
        this.sPrivateConnectionData = config.sPrivateConnectionData || config.PrivateConnectionData;

        this.nRequestTimeoutMillis = config.nRequestTimeoutMillis;
        if (this.nRequestTimeoutMillis === null || isNaN(this.nRequestTimeoutMillis)) this.nRequestTimeoutMillis = 25000;
        else if (this.nRequestTimeoutMillis < 0) this.nRequestTimeoutMillis = 0;

        this.nEventCollectWaitMillis = this.nRequestTimeoutMillis / 2;
        this.pollingIntervalMillis = this.nEventCollectWaitMillis / 2;
        if (this.pollingIntervalMillis > 5000) this.pollingIntervalMillis = 5000;

        this.sChatID = config.sChatID || config.ChatID;

        // Off, Connecting, Connected:
        this.connectionStatus = this.sChatID ? MitelChatHandler.ConnectionStatus.Connected : MitelChatHandler.ConnectionStatus.Off;

        //this.nMaxMessageLength = null;
        //this.nMaxAttachmentBytes = null;
        //this.sFileUploadAccept = null;
        //this.ttRestrictedAttachmentFileExtensions = null;
        //this.fileInput = null;

        this.nCreationTimePoint = Date.now();
        this.fileUploadEnablers = [];

        if ((this.eContainer = document.querySelectorAll('.teneo-web-chat')).length === 1) this.eContainer = this.eContainer[0];
        else {
            console.error('MitelChatHandler, found', this.eContainer.length, 'TWC containers, should be 1');
            this.eContainer = null;
        }
        this.nMaxAttachmentBytes = 0;

        this.requestChatConfiguration().then(r => {
            if (this.bDebug) console.debug('MitelChatHandler,requestChatConfiguration() result:', r);
            this.nMaxMessageLength = r.MaxMessageLength;
            if (this.eContainer && r.MaxAttachmentSize > 0 && window.File && window.File.prototype.arrayBuffer) {
                this.nMaxAttachmentBytes = r.MaxAttachmentSize * 1024;

                // Examples of "accept" values:
                // accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file

                if (r.AllowedAttachmentTypes) {
                    r.AllowedAttachmentTypes.forEach(t => {
                        if (t) {
                            if (!(t.startsWith('.') || t.includes('/'))) t = '.' + t;
                            if (this.sFileUploadAccept) this.sFileUploadAccept += ',' + t;
                            else this.sFileUploadAccept = t;
                        }
                    });
                }
                if (r.RestrictedAttachmentTypes && r.RestrictedAttachmentTypes.length > 0) this.ttRestrictedAttachmentFileExtensions = new Set(r.RestrictedAttachmentTypes);

                this._provideFileInput();
                this._callFileUploadEnablers(this.connectionStatus === MitelChatHandler.ConnectionStatus.Connected);
            } else {
                this._callFileUploadEnablers(false);
            }
        }, e => {
            console.error('MitelChatHandler, failure to obtain live chat configuration', e);
            this._callFileUploadEnablers(false);
        });
    }


    _removeFileInput() {
        if (this.fileInput) {
            this.fileInput.parentNode.removeChild(this.fileInput);
            this.fileInput = null;
        }
        if (this.eContainer) {
            for (const e of this.eContainer.querySelectorAll('.twc-file-input-MitelChatHandler')) {
                e.parentNode.removeChild(e);
            }
        }
    }


    _provideFileInput() {
        this._removeFileInput();
        if (this.eContainer == null) return;
        this.fileInput = document.createElement('input');
        this.fileInput.type = 'file';
        this.fileInput.className = '.twc-file-input-MitelChatHandler';
        this.fileInput.style.opacity = 0;
        this.fileInput.style.display = 'none';
        this.fileInput.multiple = true;
        if (this.sFileUploadAccept) this.fileInput.accept = this.sFileUploadAccept;
        const nCreationTimePoint = this.nCreationTimePoint;

        //TODO if the same files is tried twice in a row, the change event is not triggered. Fix this.
        //TODO add more fileUploadRejected logic: allowed/forbidden file extensions etc

        this.fileInput.addEventListener('change', () => {
            if (this.bDebug) console.debug('MitelChatHandler, uploading', this.fileInput.files.length, 'files');
            for (const file of this.fileInput.files) {
                // file.name is a String
                if (this.bDebug) console.debug('MitelChatHandler, uploading file', file.name, 'with size', file.size, 'and type', blob.type);
                if (file.size > this.nMaxAttachmentBytes) {
                    this._runEvents('fileUploadRejected', {
                        fileName: file.name,
                        reason: 'ATTACHMENT_SIZE_EXCEEDED',
                        maxAllowedBytes: this.nMaxAttachmentBytes,
                        actualBytes: file.size
                    });
                    console.warn('MitelChatHandler, file', file.name, 'is too big:', file.size, 'bytes; max size', this.nMaxAttachmentBytes, 'bytes');
                    return;
                }
                if (file.size === 0) {
                    this._runEvents('fileUploadRejected', { fileName: file.name, reason: 'EMPTY_ATTACHMENT' });
                    console.warn('MitelChatHandler, file', file.name, 'is empty');
                    return;
                }
                file.arrayBuffer().then(arrayBuffer => {
                    if (nCreationTimePoint !== this.nCreationTimePoint) {
                        this._runEvents('fileUploadRejected', { fileName: file.name, reason: 'WRONG_HANDLER_INSTANCE' });
                        console.error('MitelChatHandler, wrong file uploader, in Promise');
                        return;
                    }
                    if (!this.isConnected()) {
                        this._runEvents('fileUploadRejected', { fileName: file.name, reason: 'CHAT_CLOSED' });
                        console.warn('MitelChatHandler, attempting upload files via a handler which has not been connected, in Promise');
                        return;
                    }
                    if (this.bDebug) console.debug('MitelChatHandler, arrayBuffer.byteLength', arrayBuffer.byteLength, 'for file', file.name, 'with size', file.size, 'and type', file.type);
                    this._runEvents('fileUploadStarted', {
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        arrayBufferByteLength: arrayBuffer.byteLength
                    });
                    const nnBytes = Array.from(new Uint8Array(arrayBuffer));
                    this.sendAttachment(nnBytes, file.name).then(
                        r => this._runEvents('fileUploadSucceeded', {
                            fileName: file.name,
                            fileSize: file.size,
                            fileType: file.type,
                            byteArrayLength: nnBytes.length,
                            result: r
                        }),
                        e => this._runEvents('fileUploadFailed', {
                            fileName: file.name,
                            fileSize: file.size,
                            fileType: file.type,
                            byteArrayLength: nnBytes.length,
                            result: e
                        })
                    );
                });
            }
        });
        this.eContainer.appendChild(this.fileInput);
    }


    _callFileUploadEnablers(bEnable) {
        bEnable = bEnable ? true : false;
        if (this.bDebug) console.debug('MitelChatHandler, calling _callFileUploadEnablers() with arg', bEnable)
        if (this.fileInput) this.fileInput.disabled = !bEnable;
        for (const fileUploadEnabler of this.fileUploadEnablers) {
            if (fileUploadEnabler.lastArg === bEnable) {
                if (this.bDebug) console.debug('MitelChatHandler, skipping fileUploadEnabler with arg', bEnable);
                continue;
            }
            fileUploadEnabler.lastArg = bEnable;
            if (this.bDebug) console.debug('MitelChatHandler, calling fileUploadEnabler with arg', bEnable);
            try {
                fileUploadEnabler.eventFuncion(bEnable);
            } catch (err) {
                console.warn('MitelChatHandler, error in fileUploadEnabler event with value', value, ':', err);
            }
        }
    }


    /**
     * Registers a listener for the given event. The implemented events are "agentMessageReceived",
     * "agentJoined", "agentLeft", "agentTyping", "chatState", "pollingImpossible", "fileUploadEnabler".
     *
     * @param {string} sEventName the name of the event to listen for.
     * @param {function} the listener function.
     */
    on(sEventName, eventFuncion) {
        if (sEventName && eventFuncion) {
            if (!MitelChatHandler.EventNames.has(sEventName)) {
                console.info('MitelChatHandler, unknown event', sEventName, 'will be ignored; the allowed events are', MitelChatHandler.EventNames);
            }
            if (sEventName === 'fileUploadEnabler') {
                const fileUploadEnabler = {
                    eventFuncion: eventFuncion,
                    lastArg: this.fileInput != null && this.nMaxAttachmentBytes > 0 && this.connectionStatus === MitelChatHandler.ConnectionStatus.Connected
                };
                try {
                    eventFuncion(fileUploadEnabler.lastArg);
                } catch (err) {
                    console.error('MitelChatHandler, error in fileUploadEnabler', err);
                }
                this.fileUploadEnablers.push(fileUploadEnabler);
            } else {
                const xx = this.callbackMap.hasOwnProperty(sEventName) ? this.callbackMap[sEventName] : null;
                if (xx) xx.push(eventFuncion);
                else this.callbackMap[sEventName] = [eventFuncion];
            }
        }
    }


    /**
     * Runs the listeners for the given event - if there are any.
     *
     * @param {string} sEventName the name of the event.
     * @param {object} value the value passed as an argument to the listener function.
     */
    _runEvents(sEventName, value) {
        if (sEventName === 'fileUploadEnabler') this._callFileUploadEnablers(value);
        else {
            const events = this.callbackMap.hasOwnProperty(sEventName) ? this.callbackMap[sEventName] : null;
            if (events == null) return;
            var i = 0;
            do {
                try {
                    events[i](value);
                } catch (err) {
                    if (events.length === 1) console.error('MitelChatHandler, error in event', sEventName, 'with value', value, ':', err);
                    else console.error('MitelChatHandler, error in event', sEventName, 'with index', i, 'and value', value, ':', err);
                }
            } while (++i < events.length);
        }
    }


    /**
     * Performs a POST request to the Mitel chat backend with the content MIME type "application/json".
     * This method returns nothing.
     *
     * @param {string} sUrl the Mitel URL to send the request to.
     * @param {object} the object to send.
     */
    _doBlindPost(sUrl, requestParamMap) {
        const dto = { request: requestParamMap };
        const xhr = new XMLHttpRequest();
        if (this.bDebug) console.trace('MitelChatHandler, doing _doBlindPost() to', sUrl, 'with payload', dto);
        xhr.timeout = this.nRequestTimeoutMillis;
        xhr.open('POST', sUrl);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(dto));
    }


    /**
     * Performs a POST request to the Mitel chat backend with the content MIME type "application/json".
     * This method returns a Promise instance resolving to the result of the request or rejecting with the corresponding information.
     *
     * @param {string} sUrl the Mitel URL to send the request to.
     * @param {object} the object to send.
     * @param {boolean} [bDoJson=false] indicates if the expected response should be interpreted as JSON.
     * @param {string} [sProperty] indicates if the promise should resolve to the value of a particular property of the returned JSON object, and NOT to the whole object.
     *
     * @returns [Promise] a promise.
     */
    _getPostPromise(sUrl, requestParamMap, bDoJson, sProperty) {
        return new Promise((resolve, reject) => {
            const bDebug = this.bDebug;
            const dto = requestParamMap != null ? { request: requestParamMap } : null;
            const xhr = new XMLHttpRequest();
            var bProcessed;
            xhr.addEventListener('load', function() {
                if (bProcessed) return;
                bProcessed = true;
                if (this.status !== 200) {
                    const errorText = `MitelChatHandler, rejecting _getPostPromise() via ${sUrl} with response code ${this.status} and status error ${this.statusText}`;
                    const e = new Error(errorText);
                    console.error('MitelChatHandler, rejecting _getPostPromise() via', sUrl, 'with payload', dto, 'response code', this.status, 'and status error', e);
                    reject(e);
                    return;
                }
                var r;
                if (bDoJson) {
                    r = this.response;
                    if (r == null || 'string' === typeof r) {
                        try {
                            r = JSON.parse(this.responseText);
                        } catch (e) {
                            console.error('MitelChatHandler, rejecting _getPostPromise() via', sUrl, 'with payload', dto, 'responseText', this.responseText, 'and parsing error', e);
                            reject(e);
                            return;
                        }
                    }
                    if (sProperty != null) r = r[sProperty];
                } else {
                    r = this.responseText;
                }
                if (bDebug) console.trace('MitelChatHandler, resolving _getPostPromise() via', sUrl, 'with payload', dto, 'and result', r);
                resolve(r);
            });
            xhr.addEventListener('error', (e) => {
                if (bProcessed) return;
                bProcessed = true;
                console.error('MitelChatHandler, rejecting _getPostPromise() via', sUrl, 'with payload', dto, 'and error', e);
                reject(e);
            });
            xhr.addEventListener('timeout', (e) => {
                if (bProcessed) return;
                bProcessed = true;
                console.warn('MitelChatHandler, rejecting _getPostPromise() via', sUrl, 'with payload', dto, 'and timeout', e);
                reject(e);
            });
            xhr.addEventListener('abort', (e) => {
                if (bProcessed) return;
                bProcessed = true;
                console.log('MitelChatHandler, rejecting _getPostPromise() via', sUrl, 'with payload', dto, 'and abort', e);
                reject(e);
            });
            if (bDebug) console.trace('MitelChatHandler, doing _getPostPromise() to', sUrl, 'with payload', dto);
            xhr.timeout = this.nRequestTimeoutMillis;
            xhr.open('POST', sUrl);
            xhr.setRequestHeader('Content-Type', 'application/json');
            if (dto) xhr.send(JSON.stringify(dto));
            else xhr.send();
        });
    }


    /**
     * Connects the handler to the Mitel live chat backend and returns a promise
     * resolving if the connection succeeded and rejecting otherwise. If the connection succeeds,
     * ChatID is received (see the Mitel API documentation).
     *
     * @returns [Promise] a promise.
     *
     * @throws {Error} the handler must be in the idle state (neither connected nor connecting).
     */
    connect() {
        if (this.connectionStatus !== MitelChatHandler.ConnectionStatus.Off) {
            throw new Error('The handler is already being used');
        }
        const requestParam = {};

        if (this.sSessionID != null) requestParam['SessionID'] = this.sSessionID;
        if (this.sCustomerID != null) requestParam['CustomerID'] = this.sCustomerID;
        if (this.sCustomerName != null) requestParam['CustomerName'] = this.sCustomerName;
        if (this.sEmailAddress != null) requestParam['EmailAddress'] = this.sEmailAddress;
        if (this.nTenantID != null) requestParam['TenantID'] = this.nTenantID;
        if (this.sServiceGroupID != null) requestParam['ServiceGroupID'] = this.sServiceGroupID;
        if (this.sServiceGroupName != null) requestParam['ServiceGroupName'] = this.sServiceGroupName;
        if (this.sPrivateConnectionData != null) requestParam['PrivateData'] = this.sPrivateConnectionData;

        if (this.bDebug) console.debug('MitelChatHandler, connect() to', this.sRequestChatUrl, 'with params', requestParam);
        this.connectionStatus = MitelChatHandler.ConnectionStatus.Connecting;
        return this._getPostPromise(this.sRequestChatUrl, requestParam, true, 'd').then(x => {
            this.sChatID = x.ChatID;
            if (this.sChatID) {
                const bDoDisconnect = (this.connectionStatus === MitelChatHandler.ConnectionStatus.Off);
                this.connectionStatus = MitelChatHandler.ConnectionStatus.Connected;
                if (bDoDisconnect) {
                    disconnect();
                    return null;
                }
                this._callFileUploadEnablers(this.fileInput && this.nMaxAttachmentBytes > 0);
                return x;
            }
            this.connectionStatus = MitelChatHandler.ConnectionStatus.Off;
            this._callFileUploadEnablers(false);
            throw new Error('Empty ChatID, connect() failed');
        }).catch(e => {
            this.connectionStatus = MitelChatHandler.ConnectionStatus.Off;
            this._callFileUploadEnablers(false);
            return e;
        });
    }


    /**
     * Disconnects the handler from the Mitel live chat backend (if it not idle/already disconnected).
     */
    disconnect() {
        switch (this.connectionStatus) {
            case MitelChatHandler.ConnectionStatus.Connected:
                const requestParam = { ChatID: this.sChatID };
                this.sChatID = null;
                this.connectionStatus = MitelChatHandler.ConnectionStatus.Off;
                if (this.bDebug) console.debug('MitelChatHandler, disconnect() to', this.sLeaveChatUrl, 'with params', requestParam);
                this._doBlindPost(this.sLeaveChatUrl, requestParam);
                break;
            case MitelChatHandler.ConnectionStatus.Connecting:
                this.connectionStatus = MitelChatHandler.ConnectionStatus.Off;
        }
        this._callFileUploadEnablers(false);
    }


    /**
     * Sends a message to the live chat operator.
     *
     * @param {string} sMessage the message to send.
     *
     * @returns [Promise] a promise resolving if the operation succeeded and rejecting otherwise,
     *
     * @throws {Error} the handler must be connected.
     */
    sendMessage(sMessage) {
        if (!this.isConnected()) throw new Error('MitelChatHandler.sendMessage(), handler is not connected');
        var messageParts = sMessage.match(/[\s\S]{1,1024}/ig)
        var i;
        for (i = 0; i < messageParts.length - 1; i++) {
            const requestParam = {
                ChatID: this.sChatID,
                Message: messageParts[i]
            };
            this._getPostPromise(this.sSendMessageUrl, requestParam);
        }

        const requestParam = {
            ChatID: this.sChatID,
            Message: messageParts[messageParts.length - 1]
        };
        if (this.bDebug) console.debug('MitelChatHandler, sendMessage() to', this.sSendMessageUrl, 'with params', requestParam);
        return this._getPostPromise(this.sSendMessageUrl, requestParam);

        /*
		const requestParam = {
            ChatID: this.sChatID,
            Message: sMessage
        };
		if (this.bDebug) console.debug('MitelChatHandler, sendMessage() to',this.sSendMessageUrl,'with params',requestParam);
        return this._getPostPromise(this.sSendMessageUrl,requestParam); */
    }


    /**
     * Sends an attachment  to the live chat operator.
     *
     * @param {number[]} [nnAttachmentBytes] the attachment byte array.
     * @param {string} [sMessage] the message to send.
     *
     * @returns [Promise] a promise resolving if the operation succeeded and rejecting otherwise,
     *
     * @throws {Error} the handler must be connected.
     */
    sendAttachment(nnAttachmentBytes, sMessage) {
        if (!this.isConnected()) throw new Error('MitelChatHandler.sendAttachment(), handler is not connected');
        const requestParam = {
            ChatID: this.sChatID
        };
        if (nnAttachmentBytes) requestParam.Attachment = nnAttachmentBytes;
        if (sMessage) requestParam.Message = sMessage;
        if (this.bDebug) console.debug('MitelChatHandler, sendAttachment() to', this.sSendMessageUrl);
        return this._getPostPromise(this.sSendMessageUrl, requestParam);
        //TODO add FE output here
    }


    /**
     * Sends a typing indicator to the live chat operator.
     *
     * @throws {Error} the handler must be connected.
     */
    sendTypingIndicator() {
        if (!this.isConnected()) throw new Error('MitelChatHandler.sendTypingIndicator(), handler is not connected');
        const requestParam = { ChatID: this.sChatID };
        if (this.bDebug) console.debug('MitelChatHandler, sendTypingIndicator() to', this.sSendTypingUrl, 'with params', requestParam);
        this._doBlindPost(this.sSendTypingUrl, requestParam);
    }


    /**
     * Requests information about the chat queue (see the Mitel chat API documentation).
     * The returned promise resolves to the requested information.
     *
     * @returns [Promise] a promise resolving if the operation succeeded and rejecting otherwise,
     *
     * @throws {Error} the handler must be connected.
     */
    requestQueueInfo() {
        if (this.nTenantID == null) return Promise.reject(new Error('TenantID==null'));
        if (this.sServiceGroupID == null && this.sServiceGroupName == null) Promise.reject(new Error('ServiceGroupID==null && ServiceGroupName==null'));
        const requestParam = { TenantID: this.nTenantID };
        if (this.sServiceGroupID != null) requestParam['ServiceGroupID'] = this.sServiceGroupID;
        if (this.sServiceGroupName != null) requestParam['ServiceGroupName'] = this.sServiceGroupName;
        if (this.bDebug) console.debug('MitelChatHandler, requestQueueInfo() to', this.sGetQueueInfoUrl, 'with params', requestParam);
        return this._getPostPromise(this.sGetQueueInfoUrl, requestParam, true, 'd');
    }


    /**
     * Requests information about the chat state (see the Mitel chat API documentation).
     * The returned promise resolves to the requested information.
     *
     * @returns [Promise] a promise resolving if the operation succeeded and rejecting otherwise,
     *
     * @throws {Error} the handler must be connected.
     */
    requestChatInfo() {
        if (!this.isConnected()) throw new Error('MitelChatHandler.requestChatInfo(), handler is not connected');
        const requestParam = { ChatID: this.sChatID };
        if (this.bDebug) console.debug('MitelChatHandler, requestChatInfo() to', this.sGetChatInfoUrl, 'with params', requestParam);
        return this._getPostPromise(this.sGetChatInfoUrl, requestParam, true, 'd');
    }


    /**
     * Requests information about the chat configuration (see the Mitel chat API documentation).
     * The returned promise resolves to the requested information.
     *
     * @returns [Promise] a promise resolving if the operation succeeded and rejecting otherwise,
     */
    requestChatConfiguration() {
        if (this.bDebug) console.debug('MitelChatHandler, requestChatConfiguration() to', this.sGetChatConfigurationUrl);
        return this._getPostPromise(this.sGetChatConfigurationUrl, null, true, 'd');
    }


    /**
     * Starts polling for the the Mitel backend related events ("agentMessageReceived", "agentJoined",
     * "agentLeft", "agentTyping", "chatState", "pollingImpossible" and "fileUploadEnabler").
     * This method should be called after successful connection.
     * If there have been multiple subsequent request failures, the event "pollingImpossible" is triggered and the polling is stopped.
     * Also, the polling is stopped when the handler is disconnected.
     *
     * @throws {Error} the handler must be connected.
     */
    startEventPolling() {
        if (!this.isConnected()) throw new Error('MitelChatHandler.startEventPolling(), handler is not connected');
        const requestParam = {
            ChatID: this.sChatID,
            Timeout: this.nEventCollectWaitMillis
        };
        var nSubsequentErrors = 0;

        const f = () => {
            if (!this.isConnected()) return;
            if (this.bDebug) console.debug('MitelChatHandler, startEventPolling f() to', this.sGetEventsUrl, 'with params', requestParam);
            const nStartRequestMillis = Date.now();
            this._getPostPromise(this.sGetEventsUrl, requestParam, true, 'd').then(x => {
                if (this.isConnected()) {
                    if (this.bDebug) console.debug('MitelChatHandler, startEventPolling() to', this.sGetEventsUrl, 'with params', requestParam, 'result', x);
                    x.Events.forEach(liveChatEvent => {
                        const sEventName = MitelChatHandler.ChatEventTypeIntToEventName[liveChatEvent.EventType];
                        if (sEventName != null) this._runEvents(sEventName, liveChatEvent.Data);
                        else console.log('MitelChatHandler, event of unknown type', liveChatEvent);
                    });
                    nSubsequentErrors = 0;
                    const nNextCallDelay = this.pollingIntervalMillis - (Date.now() - nStartRequestMillis);
                    setTimeout(f, nNextCallDelay > 0 ? nNextCallDelay : 0);
                }
            }, e => {
                if (this.isConnected()) {
                    if (++nSubsequentErrors < 50) {
                        console.warn('MitelChatHandler, startEventPolling f() to', this.sGetEventsUrl, 'with params', requestParam, 'error', e, 'retrying');
                        const nNextCallDelay = this.pollingIntervalMillis - (Date.now() - nStartRequestMillis);
                        setTimeout(f, nNextCallDelay > 0 ? nNextCallDelay : 0);
                    } else {
                        console.error('MitelChatHandler, startEventPolling f() to', this.sGetEventsUrl, 'with params', requestParam, 'error', e, 'disconnecting because of too many subsequent errors');
                        this.disconnect();
                        this._runEvents('pollingImpossible', e);
                    }
                }
            });
        };
        setTimeout(f, 0);
    }


    /**
     * Checks if the handler is connected.
     *
     * @returns {Boolean} true if the handler is connected and false otherwise (idle or trying to connect).
     */
    isConnected() {
        return (this.connectionStatus === MitelChatHandler.ConnectionStatus.Connected);
    }
}


Object.defineProperties(MitelChatHandler, {
    ChatState: {
        value: Object.freeze({
            Queued: 0,
            Handling: 1,
            Terminated: 2
        })
    },
    ConnectionStatus: {
        value: Object.freeze({
            Off: 0,
            Connecting: 1,
            Connected: 2
        })
    },
    ChatEventTypeIntToEventName: {
        value: Object.freeze([
            //0: Message was received from agent
            'agentMessageReceived',
            //1: Agent has joined the conversation
            'agentJoined',
            //2: Agent has left the conversation
            'agentLeft',
            //3: Agent is typing
            'agentTyping',
            //4: Chat state has changed
            'chatState'
        ])
    },
    eraseInstance: {
        value: (mitelChatHandler) => {
            mitelChatHandler.disconnect();
            mitelChatHandler._removeFileInput();
            for (const s of Object.getOwnPropertyNames(mitelChatHandler)) {
                try {
                    delete mitelChatHandler[s];
                } catch (err) {
                    // Ignore
                }
            }
        }
    }
});


Object.defineProperty(MitelChatHandler, 'EventNames', {
    value: new Set(MitelChatHandler.ChatEventTypeIntToEventName)
        .add('fileUploadEnabler').add('fileUploadStarted').add('fileUploadRejected').add('fileUploadSucceeded').add('fileUploadFailed').add('pollingImpossible')
});