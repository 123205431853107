let isQuickReply = false


function onReady() {
    TeneoWebChat.call('show_callout', `Fråga mig!`)
    setTimeout(() => { TeneoWebChat.call('hide_callout') }, 3000)
}

TeneoWebChat.on('ready', onReady)

function onEngineResponse(payload) {
    const output = payload.responseDetails.output;
    const paramNameToValue = output.parameters;

    // checks if the response contains a quickreply button
    if (paramNameToValue.hasOwnProperty('teneowebclient')) {
        const button = JSON.parse(paramNameToValue.teneowebclient)
        if (button.type == "quickreply") isQuickReply = true
    }

    // if inputfieldLock == true the user cannot input text
    if (paramNameToValue.inputfieldLock == "false" || sessionStorage.twc_mitelLiveChatData) {
        TeneoWebChat.call('enable_user_input')
    } else {
        TeneoWebChat.call('disable_user_input')
    }

}
TeneoWebChat.on('engine_response', onEngineResponse)


function onVisibilityChanged(payload) {
    switch (payload.visibility) {
        case 'maximized':
            sessionStorage.setItem('closeButtonClicked', 'false')
            break
        case 'minimized':

            break
    }
}
TeneoWebChat.on('visibility_changed', onVisibilityChanged)

const onMessageButtonClicked = (payload) => {
    const button = payload.button

    if (isQuickReply) {
        printButtonTitle(button)
    }
}

TeneoWebChat.on('message_button_clicked', onMessageButtonClicked)

const printButtonTitle = (button) => {
    const buttonMessage = {
        "author": "user",
        "type": "text",
        "data": {
            "text": button.title
        }
    }

    isQuickReply = false
    TeneoWebChat.call('add_message', buttonMessage)
}

function onCloseButtonClicked(payload) {

    if (sessionStorage.getItem('closeButtonClicked') == 'false') {
        TeneoWebChat.call('add_message', closeModal)
        sessionStorage.setItem('closeButtonClicked', 'true')
        payload.handledState.handled = true
        return
    }

    window.closeLiveChat()
    setTimeout(resetChat, 100)
}

TeneoWebChat.on('close_button_clicked', onCloseButtonClicked)

function onModalButtonClicked(payload) {
    switch (payload.button.operation) {
        case 'cancel':
            sessionStorage.setItem('closeButtonClicked', 'false')
            payload.handledState.handled = true;
            break;
        case 'closeFrontend':
            window.closeLiveChat();
            resetChat()
    }
}

TeneoWebChat.on('modal_button_clicked', onModalButtonClicked)

const closeModal = {
    type: 'modal',
    author: 'bot',
    data: {
        "type": "modal",
        "title": "Är du säker på att du vill avsluta den här chattsessionen?",
        "button_items": [
            {
                "style": "primary",
                "title": "Stäng",
                "operation": "closeFrontend"
            },
            {
                "style": "primary",
                "title": "Tillbaka till chatten",
                "operation": "cancel"
            }
        ]
    }
}

/**
* Resets TeneoWebChat values
*/
function resetChat() {
    TeneoWebChat.call('reset')
}